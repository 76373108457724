import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import store from "../store/index";
import { navigateTo } from "@/router/navigateTo";
import { lowerJSONKey } from "@/utils/common";

const routes = [
  {
      path: '/',
      name: 'homePage',
      component: () => import('@/views/home'),
      hidden: true
  },
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHashHistory(),
  routes
})


export default router
